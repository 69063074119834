.project_div {
  height: 600px;
  background-color: white;
}
.out_clients {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 400px;
}

.our {
  color: #ededed;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
}
.gradient_text {
  background: linear-gradient(
    90deg,
    rgba(41, 72, 255, 0) -21.2%,
    rgba(66, 130, 254, 0.65) 13.71%,
    #42d7f8 48.17%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 80px;
  font-style: normal;
  font-weight: 500;
}
.gradient_text2 {
  background: linear-gradient(
    90deg,
    rgba(41, 72, 255, 0) -21.2%,
    rgba(66, 130, 254, 0.65) 13.71%,
    #42d7f8 48.17%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 89px;
  letter-spacing: -1px;
}
.item-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 2px;
  margin: 0 auto;
  /* max-height: 70vh; */
  display: flex;
  gap: 10px;
}
.item-wrapper {
  font-size: 18px;
  flex: 1 0 auto;
  border-radius: 40px;
  text-align: center;
  height: 600px;
  width: 4%;
  /* min-height: 700px; */
  background: center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: flex 0.5s;
  -webkit-transition: flex 0.5s;
  -moz-transition: flex 0.5s;
  -o-transition: flex 0.5s;
  position: relative;
}

.item-wrapper.expand {
  flex-grow: 3;
}
.item-wrapper.w1 {
  background-image: url("./public/static/image/workProjectsAssets/backgrounds/garbitBg.webp");
}
.item-wrapper.w2 {
  background-image: url("./public/static/image/workProjectsAssets/backgrounds/bmiBg.webp");
}
.item-wrapper.w3 {
  background-image: url("./public/static/image/workProjectsAssets/backgrounds/mediotixBg.webp");
}
.item-wrapper.w4 {
  background-image: url("./public/static/image/workProjectsAssets/backgrounds/jusplayBg.webp");
}
.client_info img {
  width: 44px;
}
.client_info div {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}
.client_info_bottom div {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}
.client_info h3 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 20px;
}
.item-wrapper .client_info_bottom {
  transform: translateY(0);
  /* display:  none; */
  transition: transform 2.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  width: 100%;
  height: 75%;
  text-align: left;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.76) 5.74%,
    rgba(0, 0, 0, 0) 99.77%
  );
  /* transition: all 2.3s ease;  */
}

.item-wrapper .client_info.active {
  /* display: block; */
  transform: translateY(0);
  /* transition: all 2.3s ease;  */
}
.item-wrapper .client_info_bottom.active {
  /* display: block; */
  transform: translateY(-200%);
  /* transition: all 2.3s ease;  */
}

@media (max-width: 992px) {
  .out_clients {
    display: none;
  }
}
@media (max-width: 700px) {
  .main_div {
    display: none;
  }
}

@media (max-width: 650px) {
  .item-container {
    flex-direction: column;

  }
  .item-wrapper {
    width: 100%;
    height: 400px;
  }
}
