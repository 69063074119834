.project_div {
  height: 600px;
  background-color: white;
}
.out_clients {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 400px;
}

.our {
  color: #ededed;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
}
.gradient_text {
  background: linear-gradient(
    90deg,
    rgba(41, 72, 255, 0) -21.2%,
    rgba(66, 130, 254, 0.65) 13.71%,
    #42d7f8 48.17%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 80px;
  font-style: normal;
  font-weight: 500;
}
.gradient_text2 {
  background: linear-gradient(
    90deg,
    rgba(41, 72, 255, 0) -21.2%,
    rgba(66, 130, 254, 0.65) 13.71%,
    #42d7f8 48.17%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 89px;
  letter-spacing: -1px;
}
.item-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 2px;
  margin: 0 auto;
  /* max-height: 70vh; */
  display: flex;
  gap: 10px;
}
.item-wrapper {
  font-size: 18px;
  flex: 1 0 auto;
  border-radius: 40px;
  text-align: center;
  height: 600px;
  width: 4%;
  /* min-height: 700px; */
  background: center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: flex 0.5s;
  -webkit-transition: flex 0.5s;
  -moz-transition: flex 0.5s;
  -o-transition: flex 0.5s;
  position: relative;
}

.item-wrapper.expand {
  flex-grow: 3;
}
.item-wrapper.w1 {
  background-image: url(/_next/static/media/garbitBg.56d431a2.webp);
}
.item-wrapper.w2 {
  background-image: url(/_next/static/media/bmiBg.5e2f233a.webp);
}
.item-wrapper.w3 {
  background-image: url(/_next/static/media/mediotixBg.213507d9.webp);
}
.item-wrapper.w4 {
  background-image: url(/_next/static/media/jusplayBg.e7a5930d.webp);
}
.client_info img {
  width: 44px;
}
.client_info div {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}
.client_info_bottom div {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}
.client_info h3 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 20px;
}
.item-wrapper .client_info_bottom {
  transform: translateY(0);
  /* display:  none; */
  transition: transform 2.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  width: 100%;
  height: 75%;
  text-align: left;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.76) 5.74%,
    rgba(0, 0, 0, 0) 99.77%
  );
  /* transition: all 2.3s ease;  */
}

.item-wrapper .client_info.active {
  /* display: block; */
  transform: translateY(0);
  /* transition: all 2.3s ease;  */
}
.item-wrapper .client_info_bottom.active {
  /* display: block; */
  transform: translateY(-200%);
  /* transition: all 2.3s ease;  */
}

@media (max-width: 992px) {
  .out_clients {
    display: none;
  }
}
@media (max-width: 700px) {
  .main_div {
    display: none;
  }
}

@media (max-width: 650px) {
  .item-container {
    flex-direction: column;

  }
  .item-wrapper {
    width: 100%;
    height: 400px;
  }
}

.glide{position:relative;width:100%;box-sizing:border-box}.glide *{box-sizing:inherit}.glide__track{overflow:hidden}.glide__slides{position:relative;width:100%;list-style:none;backface-visibility:hidden;transform-style:preserve-3d;touch-action:pan-Y;overflow:hidden;margin:0;padding:0;white-space:nowrap;display:flex;flex-wrap:nowrap;will-change:transform}.glide__slides--dragging{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.glide__slide{width:100%;height:100%;flex-shrink:0;white-space:normal;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-webkit-tap-highlight-color:transparent}.glide__slide a{-webkit-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-select:none;-ms-user-select:none}.glide__arrows{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.glide__bullets{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.glide--rtl{direction:rtl}/*# sourceMappingURL=glide.core.min.css.map */

.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s, transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: '';
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* devanagari */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/0662b626da5db789.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/1fe84a733deddad4.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/7a78f1ce0329757f.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/ac614beb32f7a7c2.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/aefc8ad6d88b3354.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/d36a2a2bb416f59e.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e025c64520263018.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/953974ac5e9ff354.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c04551857776278f.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/87c72f23c47212b9.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/591327bf3b62a611.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/916d3686010a8de2.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/839135d04a097cea.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/370d1cc320ec5619.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7777133e901cd5ed.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/3828f203592f7603.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/10939feefdad71be.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d869208648ca5469.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/20b8b8f6f47c1e10.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f93b79c1ea023ab6.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9a881e2ac07d406b.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/8d1a51bb45dd4d14.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/1b097aa12b72d9f9.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/376dd8dc38524313.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/51051a7edfeea436.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/9b44cfc48addbfc9.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_a80c87';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/bd427f25ac24d036.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_a80c87';src: local("Arial");ascent-override: 92.83%;descent-override: 30.94%;line-gap-override: 8.84%;size-adjust: 113.11%
}.__className_a80c87 {font-family: '__Poppins_a80c87', '__Poppins_Fallback_a80c87';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_9fa480';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6a3756e883e2eb8f.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_9fa480';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c5e9dea7b6590ad5.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_9fa480';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6109769e925337d8.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_9fa480';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/33621ae1b6285de3.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_9fa480';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9bbd3600fccd0524.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_9fa480';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d65e4cbce3376718.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_9fa480';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/177d2525112bed08.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Code_Pro_Fallback_9fa480';src: local("Arial");ascent-override: 74.83%;descent-override: 20.76%;line-gap-override: 0.00%;size-adjust: 131.49%
}.__className_9fa480 {font-family: '__Source_Code_Pro_9fa480', '__Source_Code_Pro_Fallback_9fa480';font-weight: 500;font-style: normal
}

